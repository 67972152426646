<template>
	<div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">运营中心</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">科普患教</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form inline class="ant-advanced-search-form">
					<el-form-item label="标题">
						<el-input placeholder="请填写文章标题" size="small" v-model="searchForm.name"></el-input>
					</el-form-item>
					<el-form-item label="推荐">
						<el-select placeholder="请选择推荐状态" size="small" v-model="searchForm.recommend">
							<el-option :value="1" label="已推荐"></el-option>
							<el-option :value="0" label="未推荐"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="类别">
						<el-select @change="menuSelect" clearable placeholder="请选择文章类别" size="small"
							v-model="searchForm.superSerial">
							<el-option v-for="(item,index) in newsTypeList" :key="index" :value="item.serial"
								:label="item.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="分类">
						<el-select v-model="searchForm.classification">
							<el-option v-for="(level,index) in newsClassificationList" :key="index"
								:value="level.serial" :label="level.name">
								<span v-for="(line,mi) in level.fullName.split('-').length-2"
									:key="mi">--</span>{{level.name}}
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i> 查询
						</el-button>
						<el-button type="primary" size="small" @click="resetSearch"><i class="fa fa-refresh"></i> 重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="frame_content mgtop10" style="min-height: 600px;">

			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="danger" size="small" @click="batchRemove"><i
						class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newItem"><i class="fa fa-plus"></i>新增文章资讯</el-button>
				<el-button type="success" size="small" @click="doExport"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table class="mgtop10" :data="newsList" :stripe="true" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="标题" prop="title">
					<template slot-scope="scope">
						<a :href="$http.webConfig.webUrls.app +scope.row.url" target="_blank">{{scope.row.title}}</a>
						<span class="mgl10">
							<el-tag size="small" v-if="scope.row.recommend==1">推荐</el-tag>
							<el-tag size="small" type="success" v-if="scope.row.banner==1">轮播图</el-tag>
						</span>
					</template>
				</el-table-column>
				<el-table-column label="分类" prop="classificationName" width="200px" />
				<el-table-column label="浏览量" prop="reading" width="80px" align="center" />
				<el-table-column label="发布时间" prop="subTime" align="center" width="160px" />
				<el-table-column label="操作" key="action" align="center" width="200px">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="24">
								<el-button v-if="scope.row.recommend==1" style="width:100%" type="success" size="small"
									@click="recommendNews(scope.$index)"><i class="fa fa-bars"></i> 取消推荐</el-button>
								<el-button v-else style="width:100%" type="info" size="small"
									@click="recommendNews(scope.$index)"><i class="fa fa-bars"></i> 推荐</el-button>
							</el-col>
							<el-col :span="12" class="mgtop10">
								<el-button style="width:100%" type="primary" size="small"
									@click="showNews(scope.$index)"><i class="fa fa-edit"></i> 编辑</el-button>
							</el-col>
							<el-col :span="12" v-if="principal.level==3" class="mgtop10">
								<el-button style="width:100%" type="danger" size="small"
									@click="removeNews(scope.$index)"><i class="fa fa-remove"></i> 删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf overflow bgffffff mgtop10">
				<div class="pdding20">
					<el-pagination v-show="newsList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
						:current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" background
						class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<fragment-news-option ref="newsOption" @addNews="addNews" @changeNews="changeNews"></fragment-news-option>
		<!-- 弹出层 end-->

		<!-- 轮播图 -->
		<el-dialog :close-on-click-modal="false" title="轮播图" :visible.sync="dialogVisible" width="600px">
			<el-form class="wf" ref="managerForm" :model="managerForm" label-width="100px" :rules="rules">
				<el-form-item label="显示位置">
					<el-select v-model="newsForm.bannerTag" style="width: 100%;">
						<el-option v-for="(item,index) in bannerTags" :key="index" :value="item.tag" :label="item.name">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="danger" @click="keepBanner(-1)" v-if="newsForm.banner==1">撤销</el-button>
				<el-button type="primary" @click="keepBanner(1)">保存</el-button>
			</span>
		</el-dialog>
		<!-- 轮播图 end-->


	</div>
</template>

<script>
	import FragmentNewsOption from '@/components/fragment/news/FragmentNewsOption.vue'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		components: {
			FragmentNewsOption
		},
		data() {
			return {
				bannerTags: [{
						name: "知识库",
						tag: "knowledge"
					},
					{
						name: "成长树",
						tag: "growth"
					},
					{
						name: "幸福屋",
						tag: "happy"
					},
					{
						name: "其他",
						tag: "other"
					}
				],
				dialogVisible: false,
				imgUploadUrl: this.$http.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogFormVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0,
					classification: ""
				},
				multipleSelection: [],
				newsTypeList: [],
				newsClassificationList: [],
				newsClassificationCacheList: [],
				newsList: [],
				mcover: '',
				newsForm: {},
				loading: false,
				imageUrl: '',
			}
		},
		methods: {
			resetSearch() {
				this.searchForm = {
					page: 1,
					pageSize: 20,
					total: 0,
					classification: ""
				}
			},
			addNews(data) {
				this.newsList.unshift(data);
			},
			changeNews(data) {
				console.log("数据修改了", data);
				Vue.set(this.newsList, data.index, data);
				this.newsList[data.index] = data;
			},
			menuSelect(item) {
				//获取当前类别的分类
				this.curTypeSerial = item;
				this.searchForm.page = 1;
				this.searchForm.superSerial = item;
				this.searchForm.classification = "";
				this.getClassificationList()
				this.getNewsList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.mcover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.mcover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}

			},
			cancel() {
				this.dialogFormVisible = false
			},
			doSearch() {
				//查询
				this.searchForm.page = 1;
				this.getNewsList();
			},
			doExport() {
				this.$http.download(this, this.$apis.material.newsExport, this.searchForm, false, res => {
					var blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					})
					var url = window.URL.createObjectURL(blob);
					var aLink = document.createElement("a");
					aLink.style.display = "none";
					aLink.href = url;
					aLink.setAttribute("download", "运营端文章导出数据.xls");
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); //下载完成移除元素
					window.URL.revokeObjectURL(url); //释放掉blob对象
				});
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getNewsList();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getNewsList();
			},
			newItem() {
				this.$refs["newsOption"].newItem();
			},
			getNewsTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.newsTypeList, {
					pageSize: 100
				}, false, res => {
					this.newsTypeList = res.data;
					//获取当前类别的分类
					this.curTypeName = this.newsTypeList[0].name;
					this.curTypeSerial = this.newsTypeList[0].serial;
				});
			},
			getClassificationList() {
				var searchForm = {
					superSerial: this.searchForm.superSerial,
					pageSize: 100
				};
				this.$http.request(this, this.$apis.material.newsClassificationList, searchForm, false, res => {
					this.newsClassificationList = res.data;
				});
			},
			getNewsList() {
				var searchForm = Object.assign({}, this.searchForm);
				searchForm.classification = searchForm.classification == "" ? searchForm.superSerial : searchForm
					.classification;
				this.$http.request(this, this.$apis.material.newsList, searchForm, false, res => {
					res.data.forEach(item => {
						item.mprice = parseFloat(item.price * 0.01).toFixed(2);
					});
					this.newsList = res.data;
					this.searchForm.total = res.count;
				});
			},
			showNews(index) {
				this.newsForm = this.newsList[index];
				this.newsForm.index = index;
				console.log("什么鬼", this.newsForm)
				this.$refs["newsOption"].showItem(this.newsForm);
			},
			removeNews(index) {
				console.log(index, this.newsList[index])
				var that = this;
				var news = this.newsList[index];
				this.$confirm('确定要删除资讯[' + news.title + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.newsRemove.replace("{serial}", news.serial);
					this.$http.request(this, url, {}, true, res => {
						this.newsList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的文章 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.material.newsBatchRemove, {
						serials: serials
					}, true, res => {
						this.newsList = this.newsList.filter(news => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == news.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
			recommendNews(index) {

				var news = this.newsList[index];
				this.$confirm('温馨提示,确定要设置资讯【' + news.title + '】的推荐状态吗, 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.newsRecommend.replace("{serial}", news.serial);
					this.$http.request(this, url, {}, true, res => {
						this.newsList[index].recommend = res.data;
					});
				})
			},
			keepBanner(type) {
				var news = this.newsForm;
				this.$confirm('确定要' + (type == -1 ? '撤销' : '设置') + '资讯[' + news.title + ']为轮播图, 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.material_bannerNews(this, news.serial, type, news.bannerTag, data => {
						this.newsForm.banner = res.data;
						this.dialogVisible = false;
					});
				});
			},
			bannerNews(index) {
				this.newsForm = this.newsList[index];
				this.dialogVisible = true;
			}
		},
		created() {
			this.getNewsTypeCacheList();
			this.getNewsList();
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}
</style>
