<template>
	<div class="wf">

		<el-dialog :close-on-click-modal="false" title="文章信息" :visible.sync="dialogVisible" width="1000px">

			<div class="wf">
				<el-form ref="newsForm" :model="newsForm" label-width="120px" :rules="rules">
					<el-row class="wf">
						<el-col :span="24">
							<el-form-item label="文章标题" prop="title">
								<el-input v-model="newsForm.title" placeholder="请填写文章标题" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所属类别" prop="superSerial">
								<el-select v-model="newsForm.superSerial" @change="menuSelect" clearable style="width: 100%;">
									<el-option v-for="(level,index) in newsTypeList" :key="index" :value="level.serial" :label="level.name"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所属分类" prop="classification">
								<el-select v-model="newsForm.classification" @change="classificationSelect" clearable style="width: 100%;">
									<el-option v-for="(level,index) in newsClassificationList" :key="index" :value="level.fullSerial" :label="level.name">
										<span v-for="(line,mi) in level.fullName.split('-').length-2" :key="mi">--</span>{{level.name}}
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="文章作者" prop="author">
								<el-input v-model="newsForm.author" placeholder="请填写文章作者" />
							</el-form-item>
							<el-form-item label="文章来源" prop="origin">
								<el-input v-model="newsForm.origin" placeholder="请填写文章来源" />
							</el-form-item>
							<el-form-item label="发布时间" prop="subTime">
								<el-date-picker v-model="newsForm.subTime" type="datetime" placeholder="选择发布时间" style="width:100%;"
								 value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="封面">
								<el-upload class="avatar-uploader" name="mdFile" :action="imgUploadUrl" :headers="uploadHeaders"
								 :show-file-list="false" :on-success="handleCoverSuccess" :before-upload="beforePicUpload">
									<img v-if="newsForm.cover" :src="newsForm.cover" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<div class="wf lineh20"> <i class="el-icon-star-off"></i>建议使用800*800正方形图片，支持JPG、PNG格式，最大1MB</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="文章简介" prop="digest">
								<el-input v-model="newsForm.digest" type="textarea" :rows="6" placeholder="请填写文章简介" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label-width="0">
								<div class="pdding10" style="border-top: 1px dashed #DEDEDE;">
									<div class="wf lineh40 bold">文章内容</div>
									<VueUEditor @ready="ueditorReady" style="min-height:600px;background: #F1F1F1;"></VueUEditor>
								</div>
							</el-form-item>
						</el-col>

					</el-row>

				</el-form>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishNews">保存</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	export default {
		name: 'News',
		components: {
			VueUEditor
		},
		data() {
			return {
				ueditor: null,
				dialogVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				loading: false,
				newsTypeList: [],
				newsClassificationList: [],
				cover: '',
				searchForm: {
					page: 1,
					pageSize: 100
				},
				newsForm: {
					cover: "",
					content: "",
					superSerial: "",
					mprice: "0.00"
				},
				rules: {
					title: [{
							required: true,
							message: '请填写文章标题',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 2 到 100个字符',
							trigger: 'blur'
						}
					],
					superSerial: [{
						required: true,
						message: '请选择所在类别',
						trigger: 'blur'
					}],
					mprice: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}, {
						pattern: /^[0-9]\d*\.\d*|0\.\d*[1-9]\d*$/,
						message: '价格为数字，例如：12.00'
					}],
					origin: [
						{
							min: 0,
							max: 200,
							message: '长度在 0 到 200个字符',
							trigger: 'blur'
						}
					],
					digest: [{
							required: true,
							message: '请填写文章简介',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 200,
							message: '长度在 2 到 200个字符',
							trigger: 'blur'
						}
					],
					subTime: [{
						required: true,
						message: '请选择发布时间',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.newsForm.cover = res.files[0].uploadUrl;
			},
			ueditorReady(ueditor) {
				let _this = this
				this.ueditor = ueditor;
				ueditor.addListener("contentChange", () => {
					_this.newsForm.content = ueditor.getContent();
				});
				if (this.newsForm.content != undefined)
					ueditor.setContent(this.newsForm.content);
			},
			menuSelect(item) {
				this.searchForm.superSerial = item;
				this.searchForm.page = 1;
				this.searchForm.name = "";
				console.log("什么鬼???", item, this.newsTypeList)
				this.newsTypeList.forEach(type => {
					if (type.serial == item) {
						this.newsForm.classificationName = type.name;
					}
				});

				this.getClassificationList();
			},
			classificationSelect(item) {
				this.newsClassificationList.forEach(type => {
					if (type.serial == item) {
						this.newsForm.classificationName = type.name;
					}
				});
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.cover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.cover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}
			},
			newItem() {
				this.newsForm = {
					mprice: "0.00",
					cover: "",
					subTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
					superSerial: "",
					classification: ""
				};

				if (this.ueditor != undefined)
					this.ueditor.setContent("");

				this.dialogVisible = true;
			},
			showItem(newsForm) {
				this.dialogVisible = true;
				var news = this.$http.cleanData(Object.assign({}, newsForm));
				var claszzes = news.classification.split("-");
				if (claszzes.length == 1)
					news.classification = "";

				news.superSerial = claszzes[0];
				this.newsForm = news;
				this.searchForm.superSerial = claszzes[0];
				if (this.newsForm.content != undefined && this.ueditor != null)
					this.ueditor.setContent(this.newsForm.content)

				this.getClassificationList();
			},
			getNewsTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.newsTypeList, {
					pageSize: 100
				}, false, res => {
					this.newsTypeList = res.data;
				});
			},
			getClassificationList() {
				this.$http.request(this, this.$apis.material.newsClassificationList, {
					superSerial: this.searchForm.superSerial,
					pageSize: 100
				}, false, res => {
					this.newsClassificationList = res.data;
				});
			},
			publishNews() {
				this.$refs['newsForm'].validate((valid) => {
					if (valid) {
						var newsForm = Object.assign({}, this.newsForm);
						var claszzes = newsForm.classification.split("-");
						newsForm.classification = claszzes[claszzes.length - 1];
						newsForm.id = "";
						newsForm.updateTime = "";
						newsForm.price = 0;

						this.$http.request(this, this.$apis.material.newsPublish, newsForm, true, (res) => {
							let data = res.data;
							if (data != null) {
								this.$emit("addNews", data)
							} else {
								newsForm.classification = newsForm.classification == "" ? newsForm.superSerial : newsForm.classification;
								this.$emit("changeNews", newsForm);
							}
							this.dialogVisible = false;
						});
					}
				});
			}
		},
		watch: {
			dialogVisible(newValue, oldValue) {
				if (newValue) {
					this.getNewsTypeCacheList();
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-default .edui-editor-iframeholder {
		height: 514px;
	}
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>


<style>
</style>
